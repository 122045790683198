import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { initWorkspaceApp } from '../store/account';
import { bindActionCreators } from 'redux';

import {
  Tabs,
  Pagination,
  message,
  Table,
  Modal,
  Form,
  Input,
  Checkbox,
  Typography,
  Button,
  Select,
  Tag,
  Space,
  notification,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import type { ColumnsType } from "antd/es/table";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { AppCustomConfig, Filters as CallbackFilters } from '../interfaces/app';
import FilterByCustomFieldsModal from "../components/FilterByCustomFieldsModal";
import ManageLayout from "../components/layouts/ManageLayout";
import NavHyperLink from "../components/NavHyperLink";
import viewUtils from "../utils/viewUtils";
import { CustomFieldConfig } from '../interfaces/app';
import { getConfig } from "../config/config";

import {
  getWorkspaceAppLinksApi,
  updateWorkspaceAppApi,
  getWorkspaceUserLinksApi,
  createWorkspaceUserLinkApi,
  updateWorkspaceUserLinkApi,
  // refreshWorkspaceAuthTokenApi,
} from "../api/ServerApi";
import { CopyOutlined } from "@ant-design/icons";

const config = getConfig();

type Props = {
  current_workspace_id: string;
  callback?: () => void;
  initWorkspaceApp?: () => void,
}

const WorkspaceAppSettingsModal = forwardRef((props:Props, ref) => {
  const { t } = useTranslation();
  const [showItem, setShowItem] = useState<WebApp | undefined>();
  const [showField, setShowFiled] = useState<CustomFieldConfig | undefined>();
  const [form] = Form.useForm();
  const filterByCustomFieldsModalRef: any = useRef();

  const filterByCustomFieldsModal = useMemo(() => {
    return (
      <FilterByCustomFieldsModal
        ref={filterByCustomFieldsModalRef}
        submitCallback={(filters: CallbackFilters) => {
          form.setFieldsValue({
            callback_filters: filters
          })
          filterByCustomFieldsModalRef.current &&
            filterByCustomFieldsModalRef.current.close();
        }}
      />
    );
  }, []);

  useImperativeHandle(ref, () => ({
    show: (record: WebApp) => {
      form.resetFields();
      form.setFieldsValue({
        baidu_map_key: record?.config?.baidu_map_key,
        gaode_map_key: record?.config?.gaode_map_key,
        enable_custom_fields: !!record?.config?.enable_custom_fields,
        custom_fields: (record?.config?.custom_fields || []).map(field => ({
          ...field,
          existed: true,
        })),
        enable_callback: !!record?.config?.enable_callback,
        callback_url_import: record?.config?.callback_url_import,
        callback_url_solve: record?.config?.callback_url_solve,
        callback_filters: record?.config?.callback_filters,
      });
      setShowItem(record);
      setShowFiled(undefined);
    }
  }))

  const handleCancel = () => {
    setShowItem(undefined)
  }

  const handleSubmit = () => {
    const data = form.getFieldsValue();
    data.custom_fields = (data.custom_fields || []).map(field => ({
      identifier: field.identifier,
      name: field.name,
      required: field.required,
      type: field.type,
      ...(!!field.values ? { values: field.values } : {})
    }))
    if(data.custom_fields.length === 0) {
      data.enable_custom_fields = false
    }
    updateWorkspaceAppApi({
      workspace_id: props.current_workspace_id,
      app_id: showItem?.id,
      active: !!showItem?.link_active,
      config: data,
    }).then(response => {
      if (response.status) {
        message.success(
          t("workspace_app_manage.tips.custom_fields.config", {
            status: t("common.success"),
          }),
        );
        !!props.callback && props.callback()
        handleCancel()
      } else {
        if(response.message?.includes("you are not authorized to use app")) {
          !!props.callback && props.callback()
          handleCancel()
        } else {
          message.error(
            t("workspace_app_manage.tips.custom_fields.config", {
              status: t("common.fail"),
            }),
          );
        }
      }
    });
  }

  const handleAddField = () => setShowFiled({});

  const EditFieldModal = () => {
    const [fieldform] = Form.useForm();
    const name = Form.useWatch("name", fieldform);

    useEffect(()=> {
      if(showField?.index_id === undefined) {
        fieldform.setFieldsValue({
          identifier: viewUtils.autoFormatIdentifier(
            name || ""
          )
        });
      }
    }, [name]);

    useEffect(() => {
      if(!!showField) {
        fieldform.resetFields();
        fieldform.setFieldsValue(showField);
      }
    // eslint-disable-next-line
    }, [ showField ])

    const handleFieldSubmit = () => {
      fieldform.validateFields().then((fieldData) => {
        const fields = form.getFieldValue("custom_fields");
        fieldData.required = !!fieldData.required;
        form.setFieldValue(
          "custom_fields",
          fieldData.index_id !== undefined ? fields.map((field, index) => {
            return fieldData.index_id === index ? fieldData : field
          }) : [...fields, fieldData]
        )
        setShowFiled(undefined)
      })
    }

    const checkValueSame = (key: string, value: string) => {
      let fields = form.getFieldValue("custom_fields");
      if(showField?.index_id !== undefined) {
        fields = fields.filter((field, index) => index !== showField?.index_id)
      }
      if (
        !!fields.find(
          (item) => item[key === "identifier" ? "identifier" : "name"] === value
        )
      ) {
        return false;
      }
      return true;
    };

    return (
      <Modal
        centered
        width={550}
        title={t(`common.${showField?.index_id !== undefined ? 'edit' : 'new' }`)}
        open={!!showField}
        onCancel={() => setShowFiled(undefined)}
        onOk={handleFieldSubmit}
        cancelText={t('common.cancel')}
        okText={t('common.save')}
      >
        <Form
          name="custom field form"
          form={fieldform}
          colon={false}
          layout='vertical'
        >
          <Form.Item name='index_id' hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name='existed' hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item
            name='name'
            label={t('workspace_app_manage.custom_field.name')}
            rules={[
              { required: true, message: '' },
              { max: 50, message: "" },
              {
                pattern:
                  /^[\u4e00-\u9fa5a-zA-Z0-9\s.,;!?'"\\/:[\]{}()-_=+*&^%$#@<>~|\uFF08-\uFF09]*$/,
                message: t('workspace_app_manage.tips.custom_fields.name_error'),
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || checkValueSame("name", value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t(
                    'workspace_app_manage.tips.custom_fields.existed',
                    { name: t('workspace_app_manage.custom_field.name')}
                  )));
                },
              }),
            ]}>
            <Input maxLength={50} showCount/>
          </Form.Item>
          <Form.Item
            name='identifier'
            tooltip={t('workspace_app_manage.tips.custom_fields.identifier_tip')}
            label={t('workspace_app_manage.custom_field.identifier')}
            rules={[
              { required: true, message: '' },
              { max: 50, message: "" },
              {
                pattern: /^[a-zA-Z_]{1,50}$/,
                message: t('workspace_app_manage.tips.custom_fields.identifier_error'),
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || checkValueSame("identifier", value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t(
                    'workspace_app_manage.tips.custom_fields.existed',
                    { name: t('workspace_app_manage.custom_field.identifier')}
                  )));
                },
              })
            ]}>
            <Input maxLength={50} showCount disabled={showField?.index_id !== undefined} />
          </Form.Item>
          <Form.Item
            name='type'
            label={t('workspace_app_manage.custom_field.type')}
            rules={[{ required: true, message: '' }]}>
            <Select
              disabled={!!showField?.existed}
              style={{ width: '100%' }}
              options={
                ['MONTH','DATE','DATETIME','TEXT','NUMBER','BOOLEAN', 'ENUM'].map(type => ({
                  label: t(`workspace_app_manage.custom_field.type_enum.${type}`),
                  value: type,
                }))
              }
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const filterType = fieldform.getFieldValue("type");
              if (filterType === "ENUM") {
                return (
                  <Form.Item
                    label={t('workspace_app_manage.custom_field.enum_values')}
                    name={'values'}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      open={false}
                    />
                  </Form.Item>
                );
              }
              return <></>;
            }}
          </Form.Item>
          <Form.Item
            name='required'
            label={''}
            valuePropName="checked">
            <Checkbox disabled={!!showField?.existed}>
              {t('workspace_app_manage.custom_field.is_field_required')}
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const handleFieldsEdit = (field: CustomFieldConfig, index: number) => {
    setShowFiled({
      ...field,
      index_id: index
    })
  }

  const handleFieldsDelete = (index: number) => {
    const fields = form.getFieldValue("custom_fields");
    form.setFieldValue("custom_fields", fields.filter((field, findex) => findex !== index))
  }

  const handleEditFilters = (config: AppCustomConfig) => {
    filterByCustomFieldsModalRef.current &&
      filterByCustomFieldsModalRef.current.show(
        config?.callback_filters || {},
        config?.custom_fields || []
      );
  };

  if(!showItem){ return null; }
  return (
    <>
      <EditFieldModal/>
      {filterByCustomFieldsModal}
      <Modal
        centered
        width={1000}
        title={t('workspace_app_manage.config')}
        open={!!showItem}
        onCancel={handleCancel}
        onOk={handleSubmit}
        cancelText={t('common.cancel')}
        okText={t('common.save')}
      >
        <Form
          name="workspace app config form"
          form={form}
        >
          <Form.Item name="link_id" hidden>
              <Input disabled />
          </Form.Item>
          {(showItem.app_id === 'bc2f3d45-cfd5-49cc-ac87-66ecad3f79fe' || [
              'flowopt-app-network-modeling-yili-yenai',
              'flowopt-app-network-modeling-yili-naifen'
            ].includes(showItem?.app_manifest?.endpoint)) && (
            <>         
              <Form.Item name="baidu_map_key" label={t('workspace_app_manage.baidu_map_key')}>
                <Input style={{ maxWidth: 500 }} />
              </Form.Item>
              <Form.Item name="gaode_map_key" label={t('workspace_app_manage.gaode_map_key')}>
                <Input style={{ maxWidth: 500 }} />
              </Form.Item>
            </>
          )}
          <Form.Item name="enable_custom_fields" valuePropName="checked">
            <Checkbox size='large'>{t('workspace_app_manage.enable_custom_fields')}</Checkbox>
          </Form.Item>
          <Form.Item name="custom_fields" hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const active = form.getFieldValue("enable_custom_fields");
              const fields = form.getFieldValue("custom_fields");
              if (!active) {
                return <></>;
              }
              return (
                <>
                  <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1em'}}>
                    <Button type='primary' onClick={handleAddField}>
                      {t('workspace_app_manage.actions.add_field')}
                    </Button>
                  </div>
                  <Table
                    style={{ marginBottom: '2em' }}
                    size={'small'}
                    pagination={false}
                    rowKey={r => r.identifier || ''}
                    columns={[
                      {
                        title: t('workspace_app_manage.custom_field.name'),
                        key: 'name',
                        render: (text, r, index) => r.name
                      },
                      {
                        title: t('workspace_app_manage.custom_field.identifier'),
                        key: 'identifier',
                        render: (text, r, index) => r.identifier
                      },
                      {
                        title: t('workspace_app_manage.custom_field.type'),
                        key: 'type',
                        render: (text, r, index) => t(`workspace_app_manage.custom_field.type_enum.${r.type}`)
                      },
                      {
                        title: t('workspace_app_manage.custom_field.required'),
                        key: 'required',
                        render: (text, r, index) => r.required && t('workspace_app_manage.custom_field.required')
                      },
                      {
                        title: t('common.actions'),
                        key: 'actions',
                        render: (text, r, index) => <>
                          <Button type='link' size='small' onClick={() => handleFieldsEdit(r, index)}>
                            {t('common.edit')}
                          </Button>
                          <Button danger type='link' size='small' onClick={() => handleFieldsDelete(index)}>
                            {t('common.delete')}
                          </Button>
                        </>
                      },
                    ] as ColumnsType<CustomFieldConfig>}
                    dataSource={fields || []}
                  />
                </>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => {
              const active = form.getFieldValue("enable_callback");
              return (
                <>
                  <Space style={{display: 'flex', flexWrap: "wrap", alignItems: 'flex-start'}}>
                    <Form.Item name="enable_callback" valuePropName="checked">
                      <Checkbox size='large'>
                        <Space>
                          {t('workspace_app_manage.enable_callback')}
                          <Tooltip placement="right" overlayStyle={{maxWidth: 'min(80vw, 800px)'}} title={
                            <>
                              <p>导入回调地址: 配置后导入状态结果可触发回调</p>
                              <p>求解回调地址: 配置后求解状态结果可触发回调</p>
                              <p>文件导入/求解成功后，通过 POST 请求的形式将加密后的结果通知到配置的相应回调地址</p>
                              <p>加密方式 AES / ECB模式 / NoPadding填充  Base64</p>
                              <p>解密过程示例：</p>
                              <p>AES('应用密钥', AES.MODE_ECB).decrypt(b64decode(enc)) </p>
                              <p>收到异步通知后，请输出 success 表示消息获取成功，系统就会停止发送异步。若未正常返回会导致服务器不断重复发送通知。重复通知特性如下：10 小时完成 8 次通知，知道成功（通知的间隔频率一般是 5m，10m,  20m，30m，1h，2h，6h）。</p>
                            </>
                          }>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Space>
                      </Checkbox>
                    </Form.Item>
                    { active && <CopyToClipboard
                      text={showItem?.app_key || ''}
                      onCopy={() => {
                        message.success(
                          t("workspace_app_manage.tips.copy_app_key", {
                            status: t("common.success"),
                          }),
                        );
                      }}
                    >
                      <Button>
                        {t("workspace_app_manage.actions.copy_app_key")}
                      </Button>
                    </CopyToClipboard>}
                  </Space>
                  <Form.Item
                    hidden={!active}
                    name="callback_url_import"
                    label={<div style={{minWidth: "10em"}}>{t('workspace_app_manage.callback_url_import')}</div>}>
                    <Input style={{ maxWidth: 500 }} />
                  </Form.Item>
                  <Form.Item
                    hidden={!active}
                    name="callback_url_solve"
                    label={<div style={{minWidth: "10em"}}>{t('workspace_app_manage.callback_url_solve')}</div>}>
                    <Input style={{ maxWidth: 500 }} />
                  </Form.Item>
                  <Form.Item hidden={true} name="callback_filters">
                    <Input />
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {() => {
                      const config = form.getFieldsValue();
                      return (
                        <Form.Item
                          hidden={!active}
                          label={<div style={{minWidth: "10em"}}>{t('workspace_app_manage.callback_filters')}</div>}>
                          <Button
                            type="link"
                            icon={<PlusCircleOutlined />}
                            onClick={() => {
                              handleEditFilters(config);
                            }}
                            style={{ paddingLeft: 0 }}
                          >
                            {config?.callback_filters &&
                            config?.callback_filters.items &&
                            config?.callback_filters.items.length > 0
                              ? t("filters.count_filters", {
                                  count: config?.callback_filters?.items?.length,
                                })
                              : t("filters.add_filters")}
                          </Button>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
})

const WorkspaceManage = (props: React.PropsWithRef<any>) => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const [tab, setTab] = useState(state?.tab || "workspace_app_manage");
  const workspaceAppSettingsModalRef: any = useRef();

  useEffect(() => {
    setTab(state?.tab || "workspace_app_manage");
  }, [state]);

  const WorkspaceAppManage = () => {
    const [apps, setApps] = useState<{ data: never[]; total: number } | null>({
      data: null,
      total: 0,
    });
    const [filter, setFilter] = useState({ page: 1, page_size: 10 });

    useEffect(() => {
      if(!!apps?.data) {
        !!props.initWorkspaceApp && props.initWorkspaceApp( apps?.data )
      }
    }, [apps])

    useEffect(() => {
      getWorkspaceAppLinksApi({
        page: filter.page,
        page_size: filter.page_size,
        workspace_id: props.current_workspace_id,
      }).then((res) => {
        if (res.status) {
          setApps({
            data: res.data || [],
            total: res?.pagination?.total || 0,
          });
        }
      });
    }, [filter]);

    const handlePageChange = (page: number, page_size: number) => {
      setFilter(data => ({ ...data, page: page, page_size: page_size }));
    };

    const handleChangeStatus = async (app_id, active) => {
      const activeApps = apps?.data?.filter(item => {
        return item.link_active
      })
      if(!active && activeApps?.length === 1) {
        Modal.confirm({
          title: t('workspace_app_manage.confirm.active_false'),
          async onOk() {
            requestUpdateWorkspaceApp(app_id, active)
          }
        });
      } else {
        requestUpdateWorkspaceApp(app_id, active)
      }
    };

    const requestUpdateWorkspaceApp = async (app_id, active) => {
      const response = await updateWorkspaceAppApi({
        workspace_id: props.current_workspace_id,
        app_id: app_id,
        active: active,
        locale: i18n.resolvedLanguage,
      });
      if (response.status) {
        setFilter({...filter})
      } else {
        if(response.message?.includes("you are not authorized to use app")) {
          setFilter({...filter})
        } else {
          message.error(
            t("workspace_app_manage.tips.update", {
              status: t("common.fail"),
            }),
          );
        }
      }
    }

    const columns: ColumnsType<WebApp> = [
      {
        title: t("app_manage.name"),
        key: "name",
        render: (r) =>
          i18n.resolvedLanguage === "en"
            ? r?.app_manifest?.display_name?.en
            : r?.app_manifest?.display_name?.zh,
      },
      {
        title: t("app_manage.description"),
        key: "description",
        width: "35%",
        render: (r) => {

          // here app_manifest.description is a string or an object(
          // { en: string, zh: string }
          // so we need to check the type of app_manifest.description
          // if it is a string, we will use it directly for both zh and en
          // if it is an object, we will check the current language and use the corresponding value
          const _description = typeof r?.app_manifest?.description === "string"
              ? r?.app_manifest?.description
              : i18n.resolvedLanguage === "en"
                  ? r?.app_manifest?.description?.en
                  : r?.app_manifest?.description?.zh
          return <Typography.Paragraph
              ellipsis={{
                rows: 2,
                tooltip: {
                  title: _description,
                  placement: "bottomLeft",
                  overlayClassName: 'description_tooltip',
                }
              }}
              className='mb-0'>
            {_description}
          </Typography.Paragraph>
        }
      },
      {
        title: t("app_manage.active"),
        key: "link_active",
        render: (r) =>
          r.link_active
            ? t("app_manage.active_true")
            : t("app_manage.active_false"),
      },
      {
        title: t("common.actions"),
        key: "actions",
        render: (r) => (
          <div className="btn-group mr-2">
            <button
              disabled={props.current_workspace_member_type === "MEMBER"}
              onClick={() => handleChangeStatus(r.id, !r.link_active)}
              className={`btn btn-sm ${
                r.link_active ? "btn-outline-secondary" : "btn-primary"
              }`}
            >
              {r.link_active
                ? t("app_manage.action.disable")
                : t("app_manage.action.enable")}
            </button>
            <CopyToClipboard
              text={r.id}
              onCopy={() => {
                message.success(
                  t("workspace_auth_manage.tips.copy_app_id", {
                    status: t("common.success"),
                  }),
                );
              }}
            >
              <button className="btn btn-sm btn-outline-secondary">
                {t("workspace_auth_manage.action.copy_app_id")}
              </button>
            </CopyToClipboard>
            <button className="btn btn-sm btn-outline-secondary" onClick={() => handleEditSettings(r)}>
              {t("workspace_app_manage.config")}
            </button>
          </div>
        ),
      },
    ];

    const workspaceAppSettingsModal = useMemo(() => (
      <WorkspaceAppSettingsModal
        ref={workspaceAppSettingsModalRef}
        current_workspace_id={props.current_workspace_id}
        callback={() => setFilter(data => ({ ...data }))}
      />
    ), [ setFilter ])

    const handleEditSettings = (data: WebApp) => workspaceAppSettingsModalRef.current && workspaceAppSettingsModalRef.current.show(data);

    return (
      <>
        {workspaceAppSettingsModal}
        <Table
          pagination={false}
          rowKey={(r) => r.id}
          columns={columns}
          dataSource={apps?.data || []}
          scroll={{ x: 700, scrollToFirstRowOnChange: true }}
        />
        <Pagination
          showQuickJumper
          hideOnSinglePage={!apps?.data?.length}
          showTotal={(total) => t("common.total", { count: total })}
          current={filter.page}
          pageSize={filter.page_size}
          total={apps?.total || 0}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={[10, 20, 50]}
          style={{ textAlign: "right", justifyContent: 'flex-end', margin: "1em" }}
        />
      </>
    );
  };

  const WorkspaceUserManage = () => {
    const [users, setUsers] = useState<{ data: never[]; total: number } | null>(
      { data: [], total: 0 }
    );
    const [filter, setFilter] = useState({ page: 1, page_size: 10 });
    const [modalUser, setModalUser] = useState<User | null>();
    const [form] = Form.useForm();
    useEffect(() => {
      getWorkspaceUserLinksApi({
        page: filter.page,
        page_size: filter.page_size,
        workspace_id: props.current_workspace_id,
        active: true,
      }).then((res) => {
        if (res.status) {
          setUsers({
            data: res.data || [],
            total: res?.pagination?.total || 0,
          });
        } else {
          setUsers(null);
        }
      });
    }, [filter]);

    const handlePageChange = (page: number, page_size: number) => {
      setFilter(data => ({ ...data, page: page, page_size: page_size }));
    };

    const showModal = (user: User) => {
      form.resetFields();
      if (!!user.id) {
        form.setFieldsValue({
          id: user.id,
          name: user.name,
          account: user.account,
          active: user.active,
          admin: user.member_type === "ADMIN",
        });
      }
      setModalUser(user);
    };

    const handleOk = async () => {
      form.validateFields().then((userData) => {
        userData.name = userData.name.trim()
        userData.account = userData.account.trim()
        if (!!userData.id) {
          updateWorkspaceUserLinkApi({
            workspace_id: props.current_workspace_id,
            link_id: userData.id,
            name: userData.name,
            account: userData.account,
            member_type: userData.admin ? "ADMIN" : "MEMBER",
          }).then((res) => {
            if (res.status && !!res.data.id) {
              message.success(
                t("workspace_user_manage.tips.update", {
                  status: t("common.success"),
                }),
              );
              setFilter((data) => ({ ...data }));
              setModalUser(null);
            } else {
              message.error(
                t("workspace_user_manage.tips.update", {
                  status: t("common.fail"),
                }),
              );
            }
          });
        } else {
          for (const user of users?.data) {
            if (user.account === userData.account) {
              message.error(
                t("workspace_user_manage.tips.user_existed", {
                  status: t("common.fail"),
                }),
              );
              return;
            }
          }
          createWorkspaceUserLinkApi({
            workspace_id: props.current_workspace_id,
            name: userData.name,
            account: userData.account,
            member_type: userData.admin ? "ADMIN" : "MEMBER",
            locale: i18n.resolvedLanguage,
          }).then((res) => {
            if (res.status && !!res.data.id) {
              message.success(
                t("workspace_user_manage.tips.add", {
                  status: t("common.success"),
                }),
              );
              setFilter((data) => ({ ...data }));
              setModalUser(null);
            } else {
              if(res.code === 409) {
                message.error(
                  t("workspace_user_manage.tips.user_existed"),
                );
              } else {
                message.error(
                  t("workspace_user_manage.tips.add", {
                    status: t("common.fail"),
                  }),
                );
              }
            }
          });
        }
      });
    };

    const handleCancel = () => {
      setModalUser(null);
    };

    const UserFormModal = () => {
      if (!modalUser) {
        return null;
      }
      return (
        <Modal
          title={
            !!modalUser!.id
              ? `${t("common.edit")}${t("workspace_user_manage.user")}`
              : `${t("common.new")}${t("workspace_user_manage.user")}`
          }
          okText={!!modalUser!.id ? t("common.save") : t("common.new")}
          cancelText={t("common.cancel")}
          open={!!modalUser}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            name="user form"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            <Form.Item label="ID" name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={t("workspace_user_manage.account")}
              name="account"
              rules={[
                { required: true, message: " " },
              ]}
            >
              <Input disabled={!!modalUser!.id}/>
            </Form.Item>
            <Form.Item
              label={t("workspace_user_manage.username")}
              name="name"
              rules={[
                { required: true, message: " " },
                {
                  max: 50,
                  message: t("workspace_user_manage.tips.name_length_invalid"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("workspace_user_manage.member_type.ADMIN")}
              name="admin"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Form>
        </Modal>
      );
    };

    const handleDeleteUser = (userData) => {
      Modal.confirm({
        title: t('workspace_user_manage.tips.confirm_delete'),
        async onOk() {
          updateWorkspaceUserLinkApi({
            workspace_id: props.current_workspace_id,
            link_id: userData.id,
            active: false
          }).then((res) => {
            if (res.status && !!res.data.id) {
              notification["success"]({
                message: t("workspace_user_manage.tips.delete", {
                  status: t("common.success"),
                }),
              });
              setFilter((data) => ({ ...data }));
            } else {
              notification["error"]({
                message: t("workspace_user_manage.tips.delete", {
                  status: t("common.fail"),
                }),
              });
            }
          });
        }
      });
    }

    const columns: ColumnsType<User> = [
      {
        title: t("workspace_user_manage.account"),
        key: "account",
        dataIndex: "account",
      },
      {
        title: t("workspace_user_manage.username"),
        key: "name",
        dataIndex: "name",
        width: 200,
      },
      {
        title: t("workspace_user_manage.created_at"),
        key: "created_at",
        render: (r) => viewUtils.utcToLocal(r.created_at),
      },
      {
        title: t("workspace_user_manage.member_type.title"),
        key: "member_type",
        render: (r) => t(`workspace_user_manage.member_type.${r.member_type}`),
      },
      {
        title: t("workspace_user_manage.created_by"),
        key: "created_by",
        render: (r) => r.member_type === "OWNER" ? 
        (
          <div style={{width: '100%', textAlign: 'center'}}>-</div>
        ) : 
        (
            <div>{r?.created_by}</div>
        ),
      },
      {
        title: t("workspace_user_manage.logined.title"),
        key: "logined",
        render: (r) => r.user_id? 
          t("workspace_user_manage.logined.active"): 
          t("workspace_user_manage.logined.inactive"),
      },
      {
        title: t("common.actions"),
        key: "actions",
        render: (r) => (
          <div className="btn-group mr-2">
            {r.member_type !== "OWNER" && (
              <>
                <button
                  disabled={props.current_workspace_member_type === "MEMBER"}
                  onClick={() => showModal(r)}
                  className="btn btn-sm btn-outline-secondary"
                >
                  {t("common.edit")}
                </button>
                <button
                  disabled={props.current_workspace_member_type === "MEMBER"}
                  onClick={() => handleDeleteUser(r)}
                  className="btn btn-sm btn-outline-secondary"
                >
                  {t("common.remove")}
                </button>
              </>
            )}
            
          </div>
        ),
      },
    ];
    return (
      <>
        <UserFormModal />
        <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center tab-action">
          <button
            disabled={props.current_workspace_member_type === "MEMBER"}
            className="btn btn-primary"
            onClick={() => showModal({})}
          >
            {t("workspace_user_manage.action.add")}
          </button>
        </div>
        <Table
          pagination={false}
          rowKey={(r) => r.id}
          columns={columns}
          dataSource={users?.data || []}
          scroll={{ x: 700, scrollToFirstRowOnChange: true }}
        />
        <Pagination
          showQuickJumper
          hideOnSinglePage={!users?.data?.length}
          showTotal={(total) => t("common.total", { count: total })}
          current={filter.page}
          pageSize={filter.page_size}
          total={users?.total}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={[10, 20, 50]}
          style={{ textAlign: "right", justifyContent: 'flex-end', margin: "1em" }}
        />
      </>
    );
  };

  const WorkspaceAuthManage = () => {
    const [clients, setClients] = useState<{
      data: never[];
      total: number;
    } | null>({ data: [], total: 0 });
    const [filter, setFilter] = useState({ page: 1, page_size: 10 });
    const [modalClient, setModalClient] = useState<Client | null>();
    const [form] = Form.useForm();
    useEffect(() => {
      getWorkspaceUserLinksApi({
        page: filter.page,
        page_size: filter.page_size,
        workspace_id: props.current_workspace_id,
        type: "client",
        active: true,
      }).then((res) => {
        if (res.status) {
          setClients({
            data: res.data || [],
            total: res?.pagination?.total || 0,
          });
        } else {
          setClients(null);
        }
      });
    }, [filter]);

    const handlePageChange = (page: number, page_size: number) => {
      setFilter(data => ({ ...data, page: page, page_size: page_size }));
    };

    const showModal = (client: Client) => {
      form.resetFields();
      if (!!client.id) {
        form.setFieldsValue({
          id: client.id,
          name: client.name,
          admin: client.member_type === "CLIENT_ADMIN"
        });
      }
      setModalClient(client);
    };

    const handleDelete = async (client: Client) => {
      Modal.confirm({
        title: t("common.confirm_delete"),
        onOk() {
          updateWorkspaceUserLinkApi({
            workspace_id: props.current_workspace_id,
            link_id: client.id,
            active: false,
          }).then((res) => {
            if (res.status && !!res.data.id) {
              message.success(
                t("workspace_auth_manage.tips.delete", {
                  status: t("common.success"),
                }),
              );
              setFilter((data) => ({
                ...data,
                page: clients.data?.length === 1 ? Math.max(data.page - 1, 1)  : data.page
              }));
            } else {
              message.error(
                t("workspace_auth_manage.tips.delete", {
                  status: t("common.fail"),
                }),
              );
            }
          });
        },
      });
    }

    // const handleRefreshToken = async (client: Client) => {
    //   refreshWorkspaceAuthTokenApi({
    //     workspace_id: props.current_workspace_id,
    //     link_id: client.id,
    //   }).then((res) => {
    //     if (res.status && !!res.data.id) {
    //       message.success(
    //         t("workspace_auth_manage.tips.refresh_token", {
    //           status: t("common.success"),
    //         }),
    //       );
    //       setFilter((data) => ({ ...data }));
    //       setModalClient(null);
    //     } else {
    //       message.error(
    //         t("workspace_auth_manage.tips.refresh_token", {
    //           status: t("common.fail"),
    //         }),
    //       );
    //     }
    //   });
    // };

    const handleOk = async () => {
      form.validateFields().then((clientData) => {
        if (!!clientData.id) {
          updateWorkspaceUserLinkApi({
            workspace_id: props.current_workspace_id,
            link_id: clientData.id,
            name: clientData.name,
            member_type: clientData.admin ? "CLIENT_ADMIN" : "CLIENT",
          }).then((res) => {
            if (res.status && !!res.data.id) {
              message.success(
                t("workspace_auth_manage.tips.update", {
                  status: t("common.success"),
                }),
              );
              setFilter((data) => ({ ...data }));
              setModalClient(null);
            } else {
              message.error(
                t("workspace_auth_manage.tips.update", {
                  status: t("common.fail"),
                }),
              );
            }
          });
        } else {
          createWorkspaceUserLinkApi({
            workspace_id: props.current_workspace_id,
            name: clientData.name,
            account: "id@client.auth",
            member_type: clientData.admin ? "CLIENT_ADMIN" : "CLIENT",
            locale: i18n.resolvedLanguage,
          }).then((res) => {
            if (res.status && !!res.data.id) {
              Modal.confirm({
                title: t("workspace_auth_manage.tips.add", {
                  status: t("common.success"),
                }),
                width: 600,
                cancelText: t("common.close"),
                content: (
                  <>
                    <Typography.Paragraph>{t("workspace_auth_manage.token")}</Typography.Paragraph>
                    <Typography.Paragraph strong code style={{ display: 'inline' }}>
                      {res.data.token}
                    </Typography.Paragraph>
                    <Button type="primary" size="small" onClick={() => handleClickCopyToken(res.data.token)}>{t("workspace_auth_manage.action.copy")}</Button>
                    <Typography.Paragraph>{t("workspace_auth_manage.tips.save_token")}</Typography.Paragraph>
                  </>
                ),
                okButtonProps: {
                  hidden: true,
                },
              });
              setFilter((data) => ({ ...data }));
              setModalClient(null);
            } else {
              message.error(
                t("workspace_auth_manage.tips.add", {
                  status: t("common.fail"),
                }),
              );
            }
          });
        }
      });
    };

    const handleClickCopyToken = (token: string) => {
      navigator.clipboard.writeText(token)
      .then(() => {
        message.success(
          t("workspace_auth_manage.tips.copy", {
            status: t("common.success"),
          })
        );
      })
      .catch(() => {
        message.error(
          t("workspace_auth_manage.tips.copy", {
            status: t("common.fail"),
          })
        );
      });
    }

    const handleCancel = () => {
      setModalClient(null);
    };

    const ClientFormModal = () => {
      if (!modalClient) {
        return null;
      }
      return (
        <Modal
          title={
            !!modalClient!.id
              ? `${t("common.edit")}${t("workspace_auth_manage.client")}`
              : `${t("common.new")}${t("workspace_auth_manage.client")}`
          }
          okText={!!modalClient!.id ? t("common.save") : t("common.new")}
          cancelText={t("common.cancel")}
          open={!!modalClient}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            name="client form"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            <Form.Item label="ID" name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={t("workspace_auth_manage.name")}
              name="name"
              rules={[{ required: true, message: " " }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("workspace_auth_manage.client_admin")}
              name="admin"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Form>
        </Modal>
      );
    };

    const columns: ColumnsType<Client> = [
      {
        title: t("workspace_auth_manage.name"),
        key: "name",
        render: (r) => <Space size={[5, 0]} style={{
          display: "flex",
          flexWrap: 'wrap'
        }}>
          {r.name}
          {
            r.member_type === 'CLIENT_ADMIN' && <Tag color="red">
              {t("workspace_auth_manage.client_admin")}
            </Tag>
          }
        </Space>
      },
      {
        title: t("workspace_auth_manage.token"),
        key: "token",
        width: "25%",
        render: (p) => (
          <Typography.Paragraph
            className='mb-0'
            style={{ wordBreak: "break-all" }}
          >
            {p.client_token}
          </Typography.Paragraph>
        ),
      },
      {
        title: t("workspace_auth_manage.expired_at"),
        key: "expired_at",
        render: (r) => viewUtils.isPermanentExpried(r.expired_at) ?
          t('common.permanent') :
          viewUtils.utcToLocal(r.expired_at),
      },
      {
        title: t("workspace_auth_manage.token_status.title"),
        key: "token_status",
        render: (r) => {
          const status = viewUtils.isExpried(r.expired_at);
          return (
            <Typography.Text type={status ? "success" : "danger"}>
              {t(
                `workspace_auth_manage.token_status.${
                  status ? "available" : "expired"
                }`
              )}
            </Typography.Text>
          );
        },
      },
      {
        title: t("common.actions"),
        key: "actions",
        render: (r) => (
          <div className="btn-group mr-2">
            <button
              onClick={() => showModal(r)}
              className="btn btn-sm btn-outline-secondary"
            >
              {t("common.edit")}
            </button>
            <button
              onClick={() => handleDelete(r)}
              className="btn btn-sm btn-outline-secondary"
            >
              {t("common.delete")}
            </button>
            {/*<CopyToClipboard
              text={r.token}
              onCopy={() => {
                message.success(
                  t("workspace_auth_manage.tips.copy", {
                    status: t("common.success"),
                  }),
                );
              }}
            >
              <button className="btn btn-sm btn-outline-secondary">
                {t("workspace_auth_manage.action.copy")}
              </button>
            </CopyToClipboard>*/}
            {/*<button*/}
            {/*    onClick={() => handleRefreshToken(r)}*/}
            {/*    className="btn btn-sm btn-outline-danger">*/}
            {/*    {t('workspace_auth_manage.action.refresh_token')}*/}
            {/*</button>*/}
          </div>
        ),
      },
    ];
    return (
      <>
        <ClientFormModal />
        <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center tab-action">
          <button
            disabled={props.current_workspace_member_type === "MEMBER"}
            className="btn btn-primary"
            style={{ marginLeft: "0.5em" }}
            onClick={() => showModal({})}
          >
            {t("workspace_auth_manage.action.add")}
          </button>
        </div>
        <div style={{marginBottom: 10}} className='d-flex align-items-center'>
              {t("workspace_auth_manage.workspace_id")} {props.current_workspace_id}
              <CopyToClipboard
                text={props.current_workspace_id}
                onCopy={() => {
                  message.success(
                    t("workspace_auth_manage.tips.copy_workspace", {
                      status: t("common.success"),
                    })
                  );
                }}
              >
                <CopyOutlined style={{marginLeft: 5}}/>
              </CopyToClipboard>
            </div>
        <Table
          pagination={false}
          rowKey={(r) => r.id}
          columns={columns}
          dataSource={clients?.data || []}
          scroll={{ x: 700, scrollToFirstRowOnChange: true }}
        />
        <Pagination
          showQuickJumper
          hideOnSinglePage={!clients?.data?.length}
          showTotal={(total) => t("common.total", { count: total })}
          current={filter.page}
          pageSize={filter.page_size}
          total={clients?.total}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={[10, 20, 50]}
          style={{ textAlign: "right", justifyContent: 'flex-end', margin: "1em" }}
        />
      </>
    );
  };

  const handleTabChange = (key: string) => setTab(key);

  return (
    <ManageLayout>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <NavHyperLink
          routes={[
            {
              path: "#",
              breadcrumbName: t("nav.workspace_manage"),
            },
          ]}
        />
      </div>
      <div className='page-layout-content-content'>
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={tab}
          onChange={handleTabChange}
          items={[{
            label: t('nav.workspace_app_manage'),
            key: 'workspace_app_manage',
            children: <WorkspaceAppManage />
          }, {
            label: t('nav.workspace_user_manage'),
            key: 'workspace_user_manage',
            children: <WorkspaceUserManage />
          }, {
            label: t('nav.workspace_auth_manage'),
            key: 'workspace_auth_manage',
            children: <WorkspaceAuthManage />
          }]}
        />
      </div>
    </ManageLayout>
  );
};

const mapStateToProps = (store) => ({
  current_workspace_id: store.account.current_workspace?.id,
  current_workspace_member_type: store.account.current_workspace?.member_type,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  initWorkspaceApp
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceManage);
