import React from "react";
import { useTranslation } from 'react-i18next';
import {Modal} from 'antd';
import moment from "moment";
import { downloadData } from '../../api/AppApi';
import {downloadExcelByBlob} from "./excelDownload";
import { RunInstance } from '../../interfaces/runInstance';

export enum ExportSheetType {Input, Output};
type Props = {
  api: any;
  instance: RunInstance;
  type: ExportSheetType;
}

const ExportSheetFromServerButton = (props: Props) => {
  const { t, i18n } = useTranslation();

  const download2Excel = async () => {
    const now = moment();
    const localTime = now.format("YYYYMMDDHHmmss");
    Modal.confirm({
      title: t(`instance.confirm.export_${
        props.type === ExportSheetType.Input ? 'input' : 'output'
      }`),
      onOk() {
        downloadData(props.api, {
            instance_id: props.instance?.id,
            data_type: props.type === ExportSheetType.Input ? 'INPUT' : 'OUTPUT',
            lang: i18n.resolvedLanguage === 'en' ? 'EN' :'ZH'
        }).then((res) => {
          if(res.status) {
            downloadExcelByBlob(
              res.data,
              `${props?.instance?.name}-export-${props.type === ExportSheetType.Input ? 'input' : 'output'}-${localTime}`
            )
          }
        })
      }
    });
  }
  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      style={{marginLeft: '0.5em'}}
      onClick={download2Excel}>
        {t(`instance.action.export_${
          props.type === ExportSheetType.Input ? 'input' : 'output'
        }`)}
    </button>
  );
}

export default ExportSheetFromServerButton;