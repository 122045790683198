const Translation = {
  app_title: "Flow Platform",
  system_manage_title: "Flow System Settings",
  nav: {
    home: "Home",
    flowopt: 'Flow App',
    planning_sessions: 'Folders',
    executions: 'Executions',
    archive: 'Archive',
    notification: 'Notification',
    docs: 'Docs',
    app: "App",
    settings: 'Settings',
    user_manage: 'User Settings',
    app_manage: 'App Settings',
    all_workspace_manage: "Workspace Settings",
    profile: 'Profile',
    system_settings: "System Data Manage",
    workspace_app_manage: "Workspace apps",
    workspace_user_manage: "Members",
    workspace_auth_manage: "Auth settings",
    workspace_manage: "Workspace Settings",
    help: "Flow Platform Docs",
    system_manage: "System Settings",
    auth_manage: "Auth Settings",
    workbench: "Workbench",
    change_language: "Language",
    official_website: "Official Website",
    empty_app_list: "No available apps",
    tip: {
      network_available: "Online",
      network_unavailable: "Offline. Please refresh the page for updates",
    }
  },
  system_manage: {
    nav: {
      dashboard: "Dashboard",
      app: "App",
      app_list: "App List",
      auth_manage: "Auth Manage",
      app_permission: "App Permission",
      flow: "Flow Data",
      instance_data: "Instance Data",
      sessions: "Folders",
      workspaces: "Workspaces",
      user: "User",
      user_list: "User List",
      chart: "Chart",
      chart_list: "Chart List",
    },
    app_list: {
      app: "App",
      title: "App Settings",
      app_id: "App ID",
      app_manifest: "App Manifest",
      name: "App Name",
      app_type: "App Type",
      app_type_private: "Private",
      app_type_public: "Public",
      workspace: 'Workspace',
      owner: "Created By",
      description: "Description",
      version: "Version",
      active: "Status",
      active_true: "Enabled",
      active_false: "Disabled",
      created_at: "Created At",
      updated_at: "Updated At",
      private_workspaces: "Workspaces",
      action: {
        register: "Register App",
        enable: "Enable",
        disable: "Disable",
      },
      tip: {
        register: "App registration {{status}}！",
        update: "App update {{status}}！",
      },
      edit_pricing: "Edit Pricing",
    },
    app_permission: {
      title: "App Permissions",
      workspace: "Workspace",
      created_at: "Created At",
      company: "Company",
      workspace_name: "Workspace",
      app_name: "App",
      app_from_time: "App From Time",
      app_to_time: "App To Time",
      app_use_time: "App Use Time",
      tip: {
        create: "create permission {{status}}！",
        save: "save permission {{status}}！",
        app_workspace_exists: "Permission with app and this workspace already exists",
        no_app_use_time: "Please config app use time",
        expired: "App is expired, please renew",
      },
    },
    instance_data: {
      workspace: "Wrkspace",
      app: "App",
      user: "User",
      created_at: "Created At",
      instance_num: "instance number",
      total_solve_time: "total solve time",
      total_solve_num: "total solve num",
      min_solve_time: "shortest solve time",
      max_solve_time: "longest solve time",
      ave_solve_time: "average solve time",
      session_num: "session num",
      s: "s",
      minute: "minute",
      times: "times",
    },
    user_list: {
      title: "User Manage",
      user: "User",
      user_id: "ID",
      email: "Email",
      account: "Account",
      is_superuser: "Superuser",
      active: "Status",
      active_true: "Active",
      active_false: "Inactive",
      created_at: "Created At",
      username: "Userame",
      workspace: "Wrkspace",
      last_login: "Last Login",
      created_by: "Inviter",
      tip: {
        update: "User update {{status}}！",
      }
    },
    chart_list: {
      title: "Chart",
      app: "App",
      chart_name: "Chart Name",
      app_name: "App Name",
      created_by: "Created By",
      created_at: "Created At",
      set_as_template: "Set as template",
      tip: {
        create: "Create chart {{status}}！",
        save: "Save chart {{status}}！",
      },
    },
  },
  home: {
    welcome: "Welcome to Flow",
    guide: "Operation Guide",
    guide_video: "Flow Quick Start Video",
    introduction: " Intro",
    all_apps: "All Apps",
    recently_viewed: "Recently Viewed",
    app_blank: "No applications are currently active in this workspace. Please activate applications in workspace settings."
  },
  instance: {
    default_session: "New Folder",
    session: "Folder",
    app: "App",
    run_instance: "File",
    info: "File Info",
    input_view: "Input View",
    output_view: "Output View",
    chart_view: "Custom Charts",
    analysis_view: "Graph View",
    name: "Name",
    shared: "Shared",
    sharer: "Sharer",
    shared_time: "Share time",
    permission: "Shared permission",
    description: "Description",
    created_at: "Created At",
    readiness_status: "Readiness Status",
    solving_status: "Solving Status",
    actions: "Actions",
    init_import: "Init Import",
    excel: "EXCEL File",
    upload_file: "Upload File",
    excel_template: "EXCEL Template",
    upload_tip: "Prepare data using the Excel template.",
    upload: {
      dragger: "Drag files here or click this area to start importing",
      accept: "Supported formats: xlsx",
    },
    owner: "Owner",
    locked: "Locked",
    unlocked: "Unlocked",
    default_memory: "default memory",
    memory_4G: "4G Memory",
    memory_8G: "8G Memory",
    memory_12G: "12G Memory",
    share_instance: "Share instance",
    members: "Members",
    can_manage: "Can modify",
    only_visit: "Read only",
    only_visit_instance: "Read only to this instance",
    can_manage_instance: "Can modify this instance",
    remove_member: "Remove member",
    action: {
      archive: "Archive",
      copy: "Copy",
      create: "Create File",
      upload: 'Click to select file to upload',
      solve: "Solve",
      export_input: "Export Data",
      export_output: "Export Data",
      share: "Share",
      update_data: "Update Data",
      unarchive: "Unarchive",
      info_update: "Update File Info",
      copy_instance_id: "Copy ID",
      lock_instance: "Lock",
      unlock_instance: "Unlock",
      copy_instance: "Copy",
      share_instance: "Share",
      delete_instance: "Delete",
      select_memory_request: "Memory request: ",
      compare: "Compare",
      compare_title: "File Compare",
      file_name: "File name",
      view_type: "View type",
      compare_table_name: "Compare table name",
      only_changed: "Only Changed",
      only_greater_than_0: "Only Greater Than 0",
      show_diff_value: "Show Diff in Value",
      show_diff_ratio: "Show Diff in Ratio",
    },
    tip: {
      archive: "Archiving of {{count}} file(s) {{status}}!",
      cannot_copy: "This file cannot be copied as it hasn't been processed successfully yet.",
      save: "File update {{status}}！",
      create: "File creation {{status}}！",
      upload_blank: 'Please upload a file or wait for the current upload to complete.',
      copy: "File copy {{status}}！",
      upload_fail: "File upload failed. Please try to upload again.",
      get_info_fail: "Failed to retrieve file information. Please try again later.",
      solve: "Model solving task submission {{status}}！",
      unarchive: "Unarchiving of {{count}} file(s) {{status}}!",
      copy_instance_id: "File ID copy {{status}}！",
      lock_instance: "File lock {{status}}！",
      unlock_instance: "File unlock {{status}}！",
      rename_instance: "File rename {{status}}!",
      delete_instance: "File deletion {{status}}!",
      get_members_fail: "Failed to retrieve members",
      get_users_fail: "Failed to retrieve users",
      update_members: "Edit permission {{status}}!",
      compare_count_error_overflow: "Only supports selecting two files for comparison operation.",
      compare_count_error_lack: "Two files need to be selected for comparison.",
      compare_count_error_status: "Only files successfully created are supported.",
      not_a_number: "The entered value is not a valid number",
    },
    confirm: {
      archive: "Are you sure you want to archive the file?",
      solve: "Are you sure you want to solve the instance?",
      export_input: "Are you sure you want to export the input data?",
      export_output: "Are you sure you want to export the output data?",
      unarchive: "Are you sure you want to unarchive the file?",
      save_update: "Are you sure you want to save the update?",
      delete: "Are you sure you want to delete this file?",
    }
  },
  chart_view: {
    title: "Chart",
    name: "Chart Name",
    select_dataframe: "Select Table",
    is_template: "Set as template",
    select_chart_type: "Select Chart Type",
    count_records_label: "Number of records",
    count_records: "Count records",
    count_a_field: "Count a field",
    select_filed: "Please select a field",
    sum: "Sum",
    min: "Min",
    max: "Max",
    average: "Average",
    empty_stacked_by: "[None]",
    sort_by_x: "Sort by X axis",
    sort_by_y: "Sort by Y axis",
    sort_rule_ascending: "Ascending",
    sort_rule_descending: "Descending",
    more_settings: "More settings",
    color_picker: "Select theme color",
    multi_color_theme: "Multi-color theme",
    monochrome_gradient_theme: "Monochrome gradient theme",
    theme_color_1: "Theme color 1",
    theme_color_2: "Theme color 2",
    theme_color_3: "Theme color 3",
    theme_color_4: "Theme color 4",
    theme_color_5: "Theme color 5",
    theme_color_6: "Theme color 6",
    theme_color_7: "Theme color 7",
    theme_color_8: "Theme color 8",
    chart_type_enum: {
      heatmap: "Heatmap",
      map: "Map",
      location_relationship: "Location Relationship Chart",
      column_chart: "Column Chart",
      stacked_column_chart: "Stacked Column Chart",
      percentage_column_chart: "Percentage Column Chart",
      bar_chart: "Bar Chart",
      stacked_bar_chart: "Stacked Bar Chart",
      percentage_bar_chart: "Percentage Bar Chart",
      pie_chart: "Pie Chart",
      donut_chart: "Donut Chart",
      line_chart: "Line chart",
      stacked_line_chart: "Stacked Line Chart",
      scatter_plot: "Scatter Plot",
      funnel_chart: "Funnel Chart",
    },
    config: {
      pie_chart: {
        value: "Values",
        dimension: "Dimension",
        text_size: "Text Size",
      },
      donut_chart: {
        value: "Values",
        dimension: "Dimension",
        text_size: "Text Size",
      },
      bar_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Group by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      stacked_bar_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      percentage_bar_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "100% Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      line_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Group by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      stacked_line_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      column_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Group by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      stacked_column_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      percentage_column_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "100% Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      gaode_heatmap: {
        opacity: "Overall transparency",
        show_tip: "Whether to display label",
        unit: "Unit of heat",
        radius: "Convergence radius",
        colors: "Color scheme",
        max: "Maximum value of heat value",
        min: "Minimum value of heat value",
        difference: "Whether to disable color transition",
        is3d: "Whether to enable 3D effect",
        px: "px",
        meter: "meter",
        latitude: 'Latitude Field',
        longitude: 'Longitude Field',
        value: 'Value Field',
      },
      location_relationship: {
        opacity: "Overall transparency",
        show_tip: "Whether to display label",
        main_latitude: 'Main Latitude Field',
        main_longitude: 'Main Longitude Field',
        sub_latitude: 'Sub Latitude Field',
        sub_longitude: 'Sub Longitude Field',
        main_name: 'Main Name Field',
        sub_name: 'Sub Name Field',
        main_size: 'Main Size',
        sub_size: 'Sub Size',
        line_width: 'Line Width',
      },
      map_type: {
        title: "Please select map type",
        default: "Default",
        gaode: "Gaode Map",
        baidu: "Baidu Map",
        input_key: "Fill in the application Key",
      },
    },
    action: {
      add: "Add Chart",
    },
    tip: {
      create: "Chart View creation {{status}}!",
      update: "Chart View update {{status}}!",
      delete: "Chart View deletion {{status}}!",
      table_blank: "Please select a table",
    },
    confirm: {
      delete: "Are you sure you want to delete this chart?",
    }
  },
  dataframe_filter: {
    filters_count: "{{count}} filter conditions",
    filter: "Filter",
    title: "Filter Config",
    when: "When",
    and: "And",
    or: "Or",
    equal: "equal to",
    unequal: "not equal to",
    include: "include",
    except: "except",
    null: "empty",
    not_null: "not empty",
    duplicate: "duplication",
    greater_than: ">",
    greater_than_or_equal_to: ">=",
    less_than: "<",
    less_than_or_equal_to: "<=",
    yes: "Yes",
    no: "No",
    add_filter: "Add filter conditions",
  },
  task_execution: {
    execution_records: "Execution Records",
    execution: "Executions",
    process_id: "Process ID",
    process_type: "Process Type",
    created_at: "Created At",
    finished_at: "Finished At",
    started_at: "Started",
    duration: "Duration",
    process_job_status: "Status",
    info: "Task Info",
    remark: "Notes",
    status_enum: {
      failed: "Failure",
      succeeded: "Success",
      running: "Running",
      pending: "Pending",
      canceled: "Canceled",
      error: "Error",
    },
    process_type_enum: {
      data_clone: "Data Clone",
      data_import: "Data Import",
      solve: "Solve",
    },
    log: {
      title: "Log",
      name: "Subtask Name",
      id: "Subtask ID",
      status: "Subtask Status",
      started_at: "Subtask Started At",
      finished_at: "Subtask Finished At",
      info: "Log Info",
      copy_log: "Copy Log",
    },
    confirm: {
      cancel_solve: "Are you sure you want to terminate the solving process?",
    },
    tip: {
      get_info_fail: "Failed to retrieve the record information. Please try again later.",
      save_remark_success: "Remark save succeeded",
      save_remark_fail: "Failed to save remark",
      cancel_solve_success: "Solving termination succeeded",
      cancel_solve_fail: "Failed to terminate the solving process",
      copy_log: "Copy Log {{status}}！",
    }
  },
  session: {
    instance_list: "Files",
    info: "Folder Info",
    planning_session: "Folder",
    all_planning_sessions: "All Folders",
    all_run_instances: "All Files",
    all_archive_sessions: "All Archive Folders",
    all_archive_instance: "All Archive Files",
    name: "Name",
    description: "Description",
    created_at: "Created At",
    actions: "Actions",
    action: {
      archive: "Archive",
      create: "Create Folder",
      unarchive: "Unarchive",
      info_update: "Update Folder Info",
      copy_session_id: "Copy Folder ID",
      delete: "Delete",
    },
    tip: {
      archive: "Archiving of {{count}} folder(s) {{status}}!",
      save: "Folder update {{status}}！",
      create: "Folder creation {{status}}！",
      unarchive: "Unachiving of {{count}} folder(s) {{status}}！",
      copy_session_id: "Folder ID copy {{status}}！",
      rename_session: "Folder rename {{status}}！",
      delete_session: "Folder deletion {{status}}！",
      contains_instance: "Folder contains files and cannot be deleted",
      delete_default: "You cannot delete the default session",
    },
    confirm: {
      archive: "Are you sure you want to archive the folder?",
      unarchive: "Are you sure you want to unarchive the folder?",
      delete: "Are you sure you want to delete the folder?"
    }
  },
  profile: {
    title: 'Profile',
  },
  user_manage: {
    title: "User Manage",
    user: "User",
    user_id: "ID",
    email: "Email",
    account: "Account",
    is_superuser: "Superuser",
    active: "Status",
    active_true: "Active",
    active_false: "Inactive",
    created_at: "Created At",
    username: "Userame",
    workspace: "Wrkspace",
    last_login: "Last Login",
    created_by: "Inviter",
    tip: {
      update: "User update {{status}}！",
    }
  },
  app_manage: {
    app: "App",
    title: "App Settings",
    app_id: "App ID",
    app_manifest: "App Manifest",
    name: "App Name",
    app_type: "App Type",
    app_type_private: "Private",
    app_type_public: "Public",
    workspace: 'Workspace',
    owner: "Created By",
    description: "Description",
    version: "Version",
    active: "Status",
    active_true: "Enabled",
    active_false: "Disabled",
    created_at: "Created At",
    updated_at: "Updated At",
    private_workspaces: "Workspaces",
    action: {
      register: "Register App",
      enable: "Enable",
      disable: "Disable",
    },
    tip: {
      register: "App registration {{status}}！",
      update: "App update {{status}}！",
    }
  },
  sheet: {
    updating: "Updating",
    decimal_format: "Decimal Format",
    add_row: "Add Row",
    tip: {
      update_success: "Data update succeeded",
      update_fail: "Data update failed. Please ensure the data format is correct and try again."
    }
  },
  workspace: {
    switch: "Switch Workspace",
    current: "Workspace",
    add: "Create Workspace",
    new: "New Workspace",
    change: "Change Name",
    name: "Workspace Name",
    create_title: "Set up your workspace",
    no_permission: "You do not have permission for the current workspace",
    quit: "Quit workspace",
    cannot_quit: "Owner cannot quit",
    confirm_quit_content: `<p>Selected workspace: <b>{{workspace}}</b></p>
    <p>If you quit, you cannot join this workspace again, are you sure to continue</p>`,
    confirm_quit: "Confirm to quit",
    tips: {
      create: "Workspace creation {{status}}！",
      update: "Update {{status}}！",
      set_default: "Workspace change {{status}}！",
      quit: "Workspace quit {{status}}！",
      no_workspace: "You currently have no available workspace, please contact the administrator",
    }
  },
  workspace_app_manage: {
    title: "Workspace apps",
    config: "Settings",
    baidu_map_key: "Baidu map key",
    gaode_map_key: "Gaode map key",
    enable_custom_fields: "Enable Custom Fields in File",
    enable_callback: "Enable file status callback",
    callback_url_import: "Import Callback Url",
    callback_url_solve: "Solve Callback Url",
    callback_filters: "Filters",
    custom_field: {
      title: "Custom Field",
      name: "Name",
      identifier: "Identifier",
      type: "Type",
      required: "Required",
      is_field_required: "Is field required",
      enum_values: "Enum Values",
      type_enum: {
        MONTH: "Date-Month",
        DATE: "Date-Day",
        DATETIME: "Datatime",
        TEXT: "Text",
        NUMBER: "Number",
        BOOLEAN: "Boolean",
        ENUM: "ENUM",
      }
    },
    actions: {
      add_field: 'Add Field',
      copy_app_key: 'Copy App Key',
    },
    tips: {
      update: "Workspace App update {{status}}！",
      custom_fields: {
        name_error: "Field name only support Chinese, English and common characters",
        identifier_tip: "The field identifier will be used as the field's unique ID, supporting only uppercase, lowercase letters, and underscores.",
        identifier_error: "Only uppercase and lowercase letters and underscores are supported",
        existed: "{{name}} already exists. Please re-enter",
        config: "Config {{status}}",
      },
      copy_app_key: 'Copy App Key {{status}}!',
    },
    confirm: {
      active_false: "If you disable this app, there would be no available app in the workspace, are you sure to continue?"
    }
  },
  workspace_user_manage: {
    title: "Workspace User Manage",
    user: "Member",
    email: "Email",
    account: "Account",
    active: "Status",
    created_at: "Created At",
    username: "Userame",
    created_by: "Inviter",
    logined: {
      title: "Activated Status",
      active: "Active",
      inactive: "Inactive",
    },
    member_type: {
      title: "Member Type",
      OWNER: "Owner",
      ADMIN: "Admin",
      MEMBER: "Member"
    },
    action: {
      add: "Invite a member",
    },
    tips: {
      add: "Workspace User addition {{status}}！",
      update: "Workspace User update {{status}}！",
      delete: "Workspace User delete {{status}}！",
      name_length_invalid: "Max name length is 50 characters",
      email_format_invalid: "Email format invalid",
      email_existed: "Email existed",
      user_existed: "User existed",
      confirm_delete: "After deleted, what this user created is still existed, are you sure to continue?",
    }
  },
  workspace_auth_manage: {
    title: "Auth settings",
    workspace_id: "Workspace ID: ",
    name: "Name",
    client: "Client",
    client_admin: "Admin",
    token: "Token",
    expired_at: "Expired At",
    active: "Status",
    token_status: {
      title: "Token Status",
      expired: "Expired",
      available: "Valid",
    },
    action: {
      add: "Create New Client",
      refresh_token: "Refresh Token",
      copy: "Copy Token",
      copy_workspace: "Copy Workspace",
      copy_app_id: "Copy App ID",
    },
    tips: {
      add: "Workspace Auth addition {{status}}！",
      update: "Workspace Auth update {{status}}！",
      delete: "Workspace Auth delete {{status}}！",
      refresh_token: "Workspace Auth Token refresh {{status}}！",
      copy: "Workspace Auth Token copy {{status}}！",
      copy_workspace: "Workspace ID copy {{status}}！",
      copy_app_id: "App ID copy {{status}}！",
      save_token:
        "Please store it carefully and ensure it is not disclosed. Once closed, you will not be able to retrieve it from the system again",
    }
  },
  platform_auth_manage: {
    title: "Platform Auth Settings",
    name: "Name",
    client: "Client",
    token: "Token",
    expired_at: "Expired At",
    active: "Status",
    token_status: {
      title: "Token Status",
      expired: "Expired",
      available: "Valid",
    },
    action: {
      refresh_token: "Refresh token",
      copy: "Copy Token",
    },
    tips: {
      refresh_token: "Platform Auth Token refresh {{status}}！",
      copy: "Platform Auth Token copy {{status}}！",
    }
  },
  notification: {
    popover: {
      app: "App",
      workspace: "Workspace",
      platform: "Platform",
      read_all: "Read all",
      release_note_title: "New version",
    },
    app: "App",
    workspace: "Workspace",
    system: "System",
    all: "All",
    read: "Read",
    unread: "Unread",
  },
  common: {
    version: "Version",
    success: "Success",
    fail: "Failed",
    pending: "Pending",
    running: "Running",
    keyword_search: "Keyword Search",
    keyword_search_execution_records: "Enter keyword to filter file by name or by description",
    keyword_search_planning_sessions: "Enter keyword to filter folder by name or by description",
    keyword_search_run_instances: "Enter keyword to filter file by name or by description",
    cancel: "Cancel",
    close: "Close",
    create: "Create",
    update: "Update",
    save: "Save",
    operation_invalid: "This operation is invalid!",
    operation_abnormal: "The operation is abnormal. Please try again!",
    archive: "Archive",
    empty: "Empty",
    actions: "Actions",
    edit: "Edit",
    new: "New",
    delete: "Delete",
    remove: "Remove",
    copy: "Copy",
    no_permission: "No Permission",
    loading: "Loading, please wait...",
    logout: "Logout",
    change_workspace: "Change Workspace",
    login: "Log in",
    search: "Search",
    open_app: "Open App",
    start_to_search: "Start typing to search",
    app_header_search: "Search Files/Folders",
    true: "Yes",
    false: "No",
    assistant: "Flow Assistant",
    add: "Add",
    comfirm: "Confirm",
    type: "Type",
    is_blank: "{{name}} is Blank",
    change: "Changed",
    first: "First",
    previous: "Previous",
    next: "Next",
    last: "Last",
    check_all: "Check All",
    list: 'List',
    card: 'Card',
    total: "Total {{count}} items",
    settings: "Settings",
    manage: "Manage",
    all: "All",
    reset: "Reset",
    query: "Query",
    red: "Red",
    pink: "Pink",
    purple: "Purple",
    deep_purple: "Deep Purple",
    indigo: "Indigo",
    blue: "Blue",
    light_blue: "Light Blue",
    cyan: "Cyan",
    teal: "Teal",
    green: "Green",
    light_green: "Light Green",
    lime: "Lime",
    yellow: "Yellow",
    amber: "Amber",
    orange: "Orange",
    deep_orange: "Deep Orange",
    brown: "Brown",
    grey: "Grey",
    blue_grey: "Blue Grey",
    server_error_tips: "Please try again later or contact customer service staff",
    permanent: "Permanent",
    confirm_delete: "Are you sure you want to delete?",
    refresh: "Refresh",
    full_screen: "Full Screen",
    exit_full_screen: "Exit Full Screen",
    update_tip: "Update {{status}}！",
  },
  supply_chain_ai_platform: {
    app_title: "Supply Chain AI Platform",
    welcome: "Welcome to Supply Chain AI",
    guide_video: "Quick Start Video",
  },
  payment: {
    request_payment_title: "Pay to Solve",
    request_payment: "You need to pay to solve the instance, because the instance has exceeded the free quota.",
    go_to_payment: "Go to Payment",
    fetch_pricing_fail: "Failed to fetch pricing information",
    create_checkout_fail: "Failed to create checkout session",
    success_title: "Payment Successful",
    success_message: "Your payment has been successfully processed!",
    session_id: "Session ID",
    cancel_title: "Payment Canceled",
    cancel_message: "Your payment was canceled.",
    go_to_dashboard: "Go to Dashboard",
    go_to_homepage: "Go to Homepage",
    choose_plan_title: "Choose a Payment Plan",
    monthly_price: "Monthly Plan",
    yearly_price: "Yearly Plan",
    choose_monthly_plan: "Choose Monthly Plan",
    choose_yearly_plan: "Choose Yearly Plan",
    loading_pricing: "Loading pricing information...",
    per_month: "per month",
    per_year: "per year",
    error_title: "Payment Error",
  },
  pricing: {
    modal_title: "Settings",
    monthly: "Monthly Price",
    yearly: "Yearly Price",
    currency: "USD",
    monthly_required: "Please enter the monthly price",
    yearly_required: "Please enter the yearly price",
    table_name: "Table Name",
    row_limit: "Row Limit",
    action: "Action",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    add_row: "Add Row",
    save_success: "Pricing saved successfully",
    save_fail: "Failed to save pricing"
  },
  filters: {
    add_filters: "Add filters conditions",
    count_filters: "{{count}} filters",
    title: "Filter Config",
    base_table_info: "Use filtering to quickly filter redundant data through condition configuration. The callback will only take effect when the filtering conditions are met.",
    when: "When",
    and: "And",
    or: "Or",
    equal: "equal to",
    unequal: "not equal to",
    include: "include",
    except: "except",
    null: "empty",
    not_null: "not empty",
    duplicate: "duplication",
    later_than: ">",
    later_than_or_equal_to: ">=",
    earlier_than: "<",
    earlier_than_or_equal_to: "<=",
    greater_than: ">",
    greater_than_or_equal_to: ">=",
    less_than: "<",
    less_than_or_equal_to: "<=",
    yes: "Yes",
    no: "No",
  }
}

export default Translation;
