import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, message, Upload, Button, Select, Typography, Image } from 'antd';
import { RunInstance } from '../../interfaces/runInstance';
import type { UploadFile } from 'antd/es/upload/interface';
import { PresignedURL } from '../../interfaces/presignedUrl';
import CustomFieldFormItem from "../../components/CustomFieldFormItem";
import GlobalLoader from "../../components/GlobalLoading";
import {downloadExcelByBlob} from "./excelDownload";

import dayjs from 'dayjs';
import {
    updateInstanceApi,
    importTaskApi,
    cloneTaskApi,
    generateUrlTaskApi,
    getSessionsApi,
} from '../../api/ServerApi';
import { connect } from 'react-redux';
import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import DownloadIcon from '../../common/images/icons/download-icon.png';
import viewUtils from '../../utils/viewUtils';
import SessionCreateButton from './SessionCreateButton';
const { Option } = Select;

export enum RunInstanceFormAction {Create, Update, Clone, CloneWithSession};
type Props = {
    app_id: string;
    action: RunInstanceFormAction;
    successCallback?: (data: RunInstance) => void;
    failCallback?: () => void;
    helpCallback?: () => void;
    default: DefaultData;
    hiddenSubmit?: boolean;
    disabled?: boolean;

    current_workspace_id?: string;
    apps?: any;
}

type SubmitData = {
    id?: string;
    session_id: string;
    name: string;
    description: string;
    upload?: FileWithPresignedURL;
}

type DefaultData = {
    id?: string;
    session_id?: string;
    session_active?: boolean;
    name?: string;
    description?: string;
}

interface FileWithPresignedURL extends UploadFile {
    presigned_url?: PresignedURL;
}

const RunInstanceForm = (props: Props) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [sessionOptions, setSessionOptions] = useState([]);
    const CurrentApp = props.apps[props.app_id];
    const CreatConfig = (CurrentApp?.app_manifest?.pipelines['IMPORT'] || []).find(
        item => item.name === "import_excel"
    ) || (CurrentApp?.app_manifest?.pipelines['IMPORT'] || [])[0]

    useEffect(() => {
        if(props.action !== RunInstanceFormAction.Update) {
            getSessionsApi({
                app_id: props.app_id,
                workspace_id: props.current_workspace_id,
                active: props.default.session_active === undefined? true: props.default.session_active,
                page: 1,
                page_size: 100,
            }).then((res) => {
                if(res.status) {
                    setSessionOptions(res.data || []);
                }
            })
        }
    }, [props.app_id, props.current_workspace_id, props.action]);

    const handleSearch = (value) => {
        getSessionsApi({
            app_id: props.app_id,
            workspace_id: props.current_workspace_id,
            active: true,
            page: 1,
            page_size: 10,
            search_key_words: value
        }).then((res) => {
            if(res.status) {
                setSessionOptions(res.data || []);
            }
        })
    }

    useImperativeHandle(props.refInstance, () => ({
        reset: (record?: RunInstance, session_id?: string) => {
            form.resetFields();
            if(!!record) {
                form.setFieldsValue({
                    session_id: record!.planning_session_id,
                    select_session_id: record!.planning_session_id,
                    id: record!.id,
                    name: record?.name,
                    description: record?.description,
                    active: record?.active
                })
            }
            if(!!session_id) {
                form.setFieldsValue({
                    session_id: session_id,
                    select_session_id: session_id,
                })
            }
        },
        submit: async () => {
            try {
              await form.validateFields();
            } catch (e) {
              return false;
            }
            form.submit();
            return true;
        }
    }))

    const create = async (data: SubmitData) => {
        if(data?.upload?.status !== 'done' || !data?.upload?.presigned_url?.path){
            message.error( t('instance.tip.upload_blank') )
            return null;
        }
        const import_response = await importTaskApi({
            session_id: data.select_session_id || data.session_id,
            name: data.name,
            description: data.description,
            custom_fields: data.custom_fields || {},
            pipeline_name: "import_excel",
            pipeline_config: {
                config: {
                    file_path: data?.upload?.presigned_url?.path,
                }
            }
        })
        if (import_response.status) {
            message.success( t('instance.tip.create', {status: t('common.success')}) )
            props!.successCallback && props!.successCallback(import_response.data)
        }else{
            if(import_response.message?.includes("this app is expired")) {
                message.error(t('system_manage.app_permission.tip.expired'))
                props!.failCallback && props!.failCallback()
            } else {
                message.error(t('instance.tip.create', {status: t('common.fail')}))
                props!.failCallback && props!.failCallback()
            }
        }
    }

    const update = async (data: SubmitData) => {
        if(!data.id) {
            message.error( t('common.operation_invalid') )
            props!.failCallback && props!.failCallback()
        } else {
            const response = await updateInstanceApi({
                instance_id: data.id,
                name: data.name,
                description: data.description,
                custom_fields: data.custom_fields || {},
                active: true,
            })
            if (response.status) {
                message.success( t('common.update_tip', {status: t('common.success')}) )
                props!.successCallback && props!.successCallback(response.data)
            } else {
                message.error( t('common.update_tip', {status: t('common.fail')}) )
                props!.failCallback && props!.failCallback()
            }
        }
    }

    const clone = async (data: SubmitData) => {
        if(!data.id) {
            message.error( t('common.operation_invalid') )
            props!.failCallback && props!.failCallback()
        } else {
            const response = await cloneTaskApi({
                session_id: data.select_session_id || data!.session_id,
                instance_id: data!.id,
                name: data.name,
                description: data.description,
                custom_fields: data.custom_fields || {},
                pipeline_name: "raw_clone",
                pipeline_config: {
                    config: {},
                },
                active: data.active
            })
            if (response.status) {
                message.success( t('instance.tip.copy', {status: t('common.success')}) )
                props!.successCallback && props!.successCallback(response.data)
            } else {
                message.error( t('instance.tip.copy', {status: t('common.fail')}) )
                props!.failCallback && props!.failCallback()
            }
        }
    }

    const formSubmit = async (data: SubmitData) => {
        const ret = {
            ...data,
            custom_fields: Object.entries(data.custom_fields || {}).reduce((ret, item) => {
                let field = (CurrentApp?.config?.custom_fields || []).find(f => f.identifier === item[0]);
                if(!!field && !!item[1]) {
                    ret[item[0]] = field.type === "MONTH" ? item[1].format('YYYY-MM') :
                                    field.type === "DATE" ? item[1].format("YYYY-MM-DD") :
                                    field.type === "DATETIME" ? item[1].format("YYYY-MM-DD HH:mm:ss") :
                                    item[1]
                }
                return ret;
            }, {})
        }
        switch(props.action)
        {
            case RunInstanceFormAction.Create:
                await create(ret);
                break;
            case RunInstanceFormAction.Update:
                if(!ret.session_id){ message.error( t('common.operation_abnormal') ); return null; }
                await update(ret);
                break;
            case RunInstanceFormAction.Clone:
            case RunInstanceFormAction.CloneWithSession:
                if(!ret.session_id){ message.error( t('common.operation_abnormal') ); return null; }
                await clone(ret);
                break;
            default:
                message.error( t('common.operation_invalid') )
        }
    }

    const normFile = (e:any) => {
        return e && e.file
    }

    const handleOpenHelp = () => {
        document.getElementById('help-iframe').src = `${CurrentApp?.doc_url[i18n.resolvedLanguage]}${CreatConfig.help_doc_url}`;
        if(document.getElementById('help-iframe-content').clientWidth === 0) {
            document.getElementById('resize-bar').style.width='55vw';
        }
        props!.helpCallback && props!.helpCallback()
    }

    const handleCreatedSession = () => {
        getSessionsApi({
            app_id: props.app_id,
            workspace_id: props.current_workspace_id,
            active: true,
            page: 1,
            page_size: 100,
        }).then((res) => {
            if(res.status) {
                setSessionOptions(res.data || []);
            }
        })
    }

    const FormDefault = props.default ? {
        ...props.default,
        description: props.default?.description || t('instance.init_import'),
        custom_fields: Object.entries(props.default?.custom_fields || {}).reduce((ret, item) => {
            let field = (CurrentApp?.config?.custom_fields || []).find(f => f.identifier === item[0]);
            if(!!field) {
                ret[item[0]] = field.type === "MONTH" ? dayjs(item[1], "YYYY-MM") :
                                field.type === "DATE" ? dayjs(item[1], "YYYY-MM-DD") :
                                field.type === "DATETIME" ? dayjs(item[1], "YYYY-MM-DD HH:mm:ss") :
                                item[1]
            }
            return ret;
        }, {
            description: t('instance.init_import')
        })
    } : undefined

    return(
        <Form
            layout='vertical'
            name={`instance-form-${props.action}`}
            form={form}
            labelAlign='left'
            initialValues={FormDefault}
            onFinish={formSubmit}>
            <Form.Item
                label={t('instance.name')}
                name='name'
                rules={[{ required: true, max: 30, message: '' }]}>
                <Input showCount maxLength={30} disabled={props.disabled} />
            </Form.Item>
            <Form.Item
                label={t('instance.description')}
                name='description'
                rules={[{ required: true, message: '' }]}>
                <Input.TextArea
                    showCount
                    maxLength={200}
                    autoSize={{minRows: 1}}
                    disabled={props.disabled}
                />
            </Form.Item>
            {
                props.action === RunInstanceFormAction.Create && <Form.Item
                    name="upload"
                    className='full-label'
                    label={
                        <div style={{ width: '100%'}}>
                            <div style={{marginRight: '1em'}}>{t('instance.upload_file')}</div>
                            <div className='mt-2 d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <Typography.Text type='secondary'>
                                        {t('instance.upload_tip')}
                                    </Typography.Text>
                                    <Button
                                        type='link'
                                        size='small'
                                        style={{ marginLeft: '0.5em'}}
                                        icon={<QuestionCircleFilled />}
                                        className='d-flex align-items-center grey-link'
                                        onClick={handleOpenHelp}
                                    />
                                </div>
                                <Button
                                    type='link'
                                    icon={<Image
                                        src={DownloadIcon}
                                        preview={false}
                                        width={'0.9em'}
                                    />}
                                    className='mx-0 d-flex align-items-center'
                                    size='small'
                                    onClick={async () => {
                                        try {
                                            GlobalLoader.show();
                                            const file_url = `${CurrentApp?.doc_url[i18n.resolvedLanguage]}${CreatConfig.sample_file_url}`;
                                            const file_name = file_url?.substring(file_url.lastIndexOf("\/") + 1, file_url.length)?.replace(".xlsx", "");
                                            let response = await fetch(file_url);
                                            let blob = await response.blob();
                                            downloadExcelByBlob(blob, file_name)
                                        } catch(e) {}
                                        GlobalLoader.hide();
                                    }}>
                                    {t('instance.excel_template')}
                                </Button>
                                {/**/}
                            </div>
                        </div>
                    }
                    valuePropName="file"
                    getValueFromEvent={normFile}
                    style={{ position: 'relative' }}
                >
                    <Upload.Dragger
                        action={async(file: FileWithPresignedURL) => {
                            const response = await generateUrlTaskApi({
                                file_name: file.name,
                                file_type: file.type,
                                method: "put_object",
                            })
                            if (response.status) {
                                const data: PresignedURL = response.data;
                                file.presigned_url = data
                                return data.pre_signed_url
                            }else{
                                message.error( t('instance.tip.upload_fail') )
                                return ""
                            }
                        }}
                        customRequest={async(options) => {
                            const { onSuccess, onError, file, action } = options
                            const upload_response = await fetch(action, {
                                method: 'PUT',
                                body: file
                            })
                            if (upload_response.ok) {
                                onSuccess && onSuccess(file)
                            }else {
                                message.error( t('instance.tip.upload_fail') )
                                onError && onError({message: t('instance.tip.upload_fail'), name: 'error'})
                            }
                        }}
                        listType="text"
                        maxCount={1}
                        accept={'.xlsx'}
                        headers={{'Content-Type': "text/csv"}}>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='d-flex justify-content-center align-items-center' style={{
                                background: "rgba(42,97,255,0.1)",
                                borderRadius: '50%',
                                width: '22px',
                                height: "22px",
                                marginBottom: 10
                            }}>
                                <PlusOutlined style={{ color: '#2A61FF', fontSize: '14px'}} />
                            </div>
                            <p style={{color: "rgba(0,0,0,0.45)"}}>{t('instance.upload.dragger')}</p>
                            <p style={{color: "rgba(0,0,0,0.25)"}}>{t('instance.upload.accept')}</p>
                        </div>
                    </Upload.Dragger>
                </Form.Item>
            }
            <Form.Item name='id' hidden><Input /></Form.Item>
            <Form.Item name='session_id' hidden><Input /></Form.Item>
            <Form.Item name='active' hidden><Input /></Form.Item>
            {
                !! CurrentApp?.config?.enable_custom_fields &&
                (CurrentApp?.config?.custom_fields || []).map(field => (
                    <CustomFieldFormItem
                        key={field.identifier}
                        name={['custom_fields', field.identifier]}
                        field={field}
                    />
                ))
            }
            {
                (props.action === RunInstanceFormAction.CloneWithSession || 
                (props.action === RunInstanceFormAction.Create && !props.default.session_id)) && 
                <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                    <Form.Item
                        label={t('instance.session')}
                        name='select_session_id'
                        style={{flex: 1}}
                        rules={[{ required: true, message: '' }]}
                    >
                        <Select
                            showSearch
                            style={{width: '100%'}}
                            defaultValue={sessionOptions.find(option => option.id === props.default.session_id)?.id}
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={handleSearch}
                        >
                            {sessionOptions.map(item => (
                                <Option key={item.id} value={item.id}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <div
                                            style={{
                                                flexGrow: 1,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                width: '200px'
                                            }}
                                            title={item.name}
                                        >
                                            {item.name}
                                        </div>
                                        <div style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>
                                            {viewUtils.utcToLocal(item.created_at, 'YYYY-MM-DD HH:mm:ss')}
                                        </div>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <SessionCreateButton app_id={props.app_id} successCallback={handleCreatedSession}>
                        <Button style={{paddingRight: 0}} type='link'>{t("common.new")}</Button>
                    </SessionCreateButton>
                </div>
            }
            {
                !props.disabled && !props.hiddenSubmit && <button className="btn btn-primary" type='submit'>
                { t(`common.${props.action === RunInstanceFormAction.Create ? 'create' : 'save'}`)}
                </button>
            }
        </Form>
    )
}

const mapStateToProps = (store) => ({
  current_workspace_id: store.account.current_workspace?.id,
  apps: store.account.apps,
})

let Component = connect(mapStateToProps, {})(RunInstanceForm)
export default forwardRef((props, ref) => <Component {...props} refInstance={ref} />);
