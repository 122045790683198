import React, { forwardRef, useState, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Select,
  Typography,
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
} from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Filters as CallbackFilters, CustomFieldConfig as CustomField, FilterItem as CallbackFilterItem} from '../interfaces/app';
import dayjs from "dayjs";

const FilterByCustomFieldsModal = forwardRef(
  (
    props: {
      submitCallback?: (data: CallbackFilters) => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [fields, setFields] = useState<CustomField[]>([]);
    const [showItem, setShowItem] = useState<CallbackFilters | undefined>();
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      show: (filters: CallbackFilters, fields: CustomField[]) => {
        setShowItem(filters);
        setFields(fields);
        form.resetFields();
        form.setFieldsValue({
          type: filters?.type || "AND",
          items: (filters?.items || []).map((item) => {
            let date = null;
            try {
                if(!!item.value) {
                    const fieldType = fields.find((f) => f.identifier === item.field)?.type;
                    if(fieldType === "MONTH") {
                        date = dayjs(item.value, "YYYY-MM")
                    }
                    if(fieldType === "DATE") {
                        date = dayjs(item.value, "YYYY-MM-DD")
                    }
                    if(fieldType === "DATETIME") {
                        date = dayjs(item.value, "YYYY-MM-DD HH:mm:ss")
                    }
                }
            } catch (e) {}
            return {
              ...item,
              date: date,
            };
          }),
        });
      },
      close: () => {
        handleCancel();
      },
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      let res = form.getFieldsValue();
      res.items = (res.items || []).map((data: CallbackFilterItem) => {
        let date = data.date;
        let field = (fields || []).find(f => f.identifier === data.field);
        try {
          if (!!field && !!date?.format) {
            if(field.type === "MONTH") {
                date = date.format("YYYY-MM");
            }
            if(field.type === "DATE") {
                date = date.format("YYYY-MM-DD");
            }
            if(field.type === "DATETIME") {
                date = date.format("YYYY-MM-DD HH:mm:ss");
            }
          }
        } catch (e) {}
        return {
          field: data.field,
          type: data.type,
          value: date || data.value,
        };
      });
      res.type = res.type || 'AND';
      props.submitCallback && props.submitCallback(res);
    };

    const handleAdd = () => {
      setShowItem((data) => ({
        ...data,
        items: [...(data?.items || []), {}],
      }));
    };

    const handleDelete = (index: number) => {
      const items = (form.getFieldValue("items") || []).filter(
        (item: CallbackFilterItem, idx: number) => idx !== index
      );
      setShowItem((data) => ({
        ...data,
        items: (data?.items || []).filter((item, idx) => idx !== index),
      }));
      form.setFieldValue("items", items);
    };

    return (
      <Modal
        width={700}
        centered
        title={t("filters.title")}
        open={!!showItem}
        onCancel={handleCancel}
        onOk={handleSubmit}
      >
        <Form form={form} id="filter-form">
          <Typography.Paragraph type="secondary">
            {t("filters.base_table_info")}
          </Typography.Paragraph>
          {(showItem?.items || []).map(
            (item: CallbackFilterItem, index: number) => (
              <div
                key={index}
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              >
                <Form.Item
                  {...(index > 0
                    ? {
                        name: "type",
                      }
                    : {})}
                >
                  <Select
                    {...(index === 0
                      ? {
                          defaultValue: "",
                        }
                      : {})}
                    disabled={index === 0}
                    style={{ width: "6em" }}
                    options={
                      index === 0
                        ? [{ label: t("filters.when"), value: "" }]
                        : [
                            { label: t("filters.and"), value: "AND" },
                            { label: t("filters.or"), value: "OR" },
                          ]
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={["items", index, "field"]}
                  style={{ marginLeft: "0.5em" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "10em" }}
                    options={fields.map((field: CustomField) => ({
                      label: field.name,
                      value: field.identifier,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  shouldUpdate
                  style={{ marginLeft: "0.5em", marginBottom: "0px" }}
                >
                  {() => {
                    const field = fields.find(
                      (field) =>
                        field.identifier ===
                        (form.getFieldValue("items") || [])[index]?.field
                    );
                    if (!field) return <></>;
                    return (
                      <Form.Item
                        name={["items", index, "type"]}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "9em" }}
                          options={[
                            { label: t("filters.equal"), value: "=" },
                            ...(field?.type === "TEXT"
                              ? [
                                  {
                                    label: t("filters.unequal"),
                                    value: "!=",
                                  },
                                  {
                                    label: t("filters.include"),
                                    value: "INCLUDE",
                                  },
                                  {
                                    label: t("filters.except"),
                                    value: "EXCEPT",
                                  },
                                  {
                                    label: t("filters.null"),
                                    value: "NULL",
                                  },
                                  {
                                    label: t("filters.not_null"),
                                    value: "NOT NULL",
                                  }
                                ]
                              : []),
                            ...(field?.type === "NUMBER"
                              ? [
                                  {
                                    label: t("filters.unequal"),
                                    value: "!=",
                                  },
                                  {
                                    label: t("filters.greater_than"),
                                    value: ">",
                                  },
                                  {
                                    label: t(
                                      "filters.greater_than_or_equal_to"
                                    ),
                                    value: ">=",
                                  },
                                  {
                                    label: t("filters.less_than"),
                                    value: "<",
                                  },
                                  {
                                    label: t(
                                      "filters.less_than_or_equal_to"
                                    ),
                                    value: "<=",
                                  },
                                  {
                                    label: t("filters.null"),
                                    value: "NULL",
                                  },
                                ]
                              : []),
                            ...(["MONTH", "DATE", "DATETIME"].includes(field?.type)
                              ? [
                                  {
                                    label: t("filters.later_than"),
                                    value: ">",
                                  },
                                  {
                                    label: t(
                                      "filters.later_than_or_equal_to"
                                    ),
                                    value: ">=",
                                  },
                                  {
                                    label: t("filters.earlier_than"),
                                    value: "<",
                                  },
                                  {
                                    label: t(
                                      "filters.earlier_than_or_equal_to"
                                    ),
                                    value: "<=",
                                  },
                                  {
                                    label: t("filters.null"),
                                    value: "NULL",
                                  },
                                  {
                                    label: t("filters.not_null"),
                                    value: "NOT NULL",
                                  },
                                ]
                              : []),
                            ...(field?.type === "BOOLEAN"
                              ? [
                                  {
                                    label: t("filters.unequal"),
                                    value: "!=",
                                  },
                                ]
                              : []),
                            ...(field?.type === "ENUM"
                              ? [
                                  {
                                    label: t("filters.unequal"),
                                    value: "!=",
                                  },
                                ]
                              : []),
                          ]}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  shouldUpdate
                  style={{ flex: 1, marginLeft: "0.5em", marginBottom: "0px" }}
                >
                  {() => {
                    const field = fields.find(
                      (field) =>
                        field.identifier ===
                        (form.getFieldValue("items") || [])[index]?.field
                    );
                    const filterType = (form.getFieldValue("items") || [])[
                      index
                    ]?.type;
                    if (!field || !filterType) return <></>;
                    if (["NULL", "NOT NULL"].includes(filterType))
                      return <></>;
                    if (field.type === "TEXT") {
                      return (
                        <Form.Item
                          name={["items", index, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      );
                    }
                    if (field.type === "NUMBER") {
                      return (
                        <Form.Item
                          name={["items", index, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                              type: "number",
                            },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      );
                    }
                    if (field.type === "MONTH") {
                      return (
                        <Form.Item
                          name={["items", index, "date"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <DatePicker picker="month" style={{width: '100%'}} format={'YYYY-MM'} />
                        </Form.Item>
                      );
                    }
                    if (field.type === "DATE") {
                      return (
                        <Form.Item
                          name={["items", index, "date"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <DatePicker style={{width: '100%'}} format={'YYYY-MM-DD'} />
                        </Form.Item>
                      );
                    }
                    if (field.type === "DATETIME") {
                      return (
                        <Form.Item
                          name={["items", index, "date"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <DatePicker showTime style={{width: '100%'}} format={'YYYY-MM-DD HH:mm:ss'} />
                        </Form.Item>
                      );
                    }
                    if (field.type === "BOOLEAN") {
                      return (
                        <Form.Item
                          name={["items", index, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            options={[
                              { label: t("filters.yes"), value: 1 },
                              { label: t("filters.no"), value: 0 },
                            ]}
                          />
                        </Form.Item>
                      );
                    }
                    if (field.type === "ENUM") {
                      return (
                        <Form.Item
                          name={["items", index, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            options={(field.values || []).map(value => ({
                                label: value,
                                value: value
                            }))}
                          />
                        </Form.Item>
                      );
                    }
                    return <></>;
                  }}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(index)}
                  />
                </Form.Item>
              </div>
            )
          )}
          <div>
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              style={{ padding: "10px 0 20px 0" }}
              onClick={handleAdd}
            >
              {t("filters.add_filters")}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
);

export default FilterByCustomFieldsModal;